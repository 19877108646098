/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://sdgyfpyjbjh2zjajl7opmyglv4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-tdm6hhfhzjctplf56vzmchzdjq",
    "aws_cognito_identity_pool_id": "us-east-1:abf9b3a8-6582-4151-9212-0eb58e07577a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_W9Qv4Nz1O",
    "aws_user_pools_web_client_id": "4sq9pik87r62c6f442dtt1v6m2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "questionaria-ai162455-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
