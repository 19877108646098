import Amplify, { Storage }  from 'aws-amplify';
import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import jStat, {uniform,gamma} from 'jStat';
import Button from 'react-bootstrap/Button';
import labels from './Labels';
import * as tf from '@tensorflow/tfjs';
const levenshtein = require('js-levenshtein');

var model=null;
async function get_policy_model(){
    
    try{
        let response = await Storage.get("brains/model.json",{download: true, expires: 3600});
        const url= window.URL.createObjectURL(new Blob([response.Body]));
        let response_weigths = await Storage.get("brains/group1-shard1of1.bin",{download: true, expires: 3600});
        const url_weigths= window.URL.createObjectURL(new Blob([response_weigths.Body]));
            
        localStorage.setItem("url_model",url);
        const response_blob =await fetch(url);
        const model_blob=await response_blob.blob();

        const response__blob_weigths =await fetch(url_weigths);
        const weigths_blob=await response__blob_weigths.blob();

        const modelJSONFile = new File([model_blob], 'model.json');
        const modelWeightsFile = new File([weigths_blob], 'group1-shard1of1.bin');
        model = await tf.loadLayersModel(tf.io.browserFiles([modelJSONFile, modelWeightsFile]));
        }
    catch (error) {
        }
    };
get_policy_model();

const x_axis=jStat.arange(33);
const a=1;
const b=32;
const uniform_pdf = x_axis.map((x) => uniform.pdf( x, a, b));
const uniform_logpb = jStat(uniform_pdf).log()[0];


var w = window.innerWidth;
var h = window.innerHeight;


const time_limit=60;
var average_state=1.0;
var reward=0.0;


const Quest=()=>{
    const quest = JSON.parse(localStorage.getItem('questionnaire')); 
    const questionnaire=quest.questionnaire;
    const session = JSON.parse(localStorage.getItem('session'));
    const limit=questionnaire.length;
    var queue=useRef(session.queue);
    var episode=useRef(session.episode);
    var [idx, setIdx] = useState(session.idx);
    var [card_states,setCard_states]=useState(session.card_states);
    var [logged,setLogged]=useState(session.username!=="No user");
    var [saving,setSaving]=useState(false);
    localStorage.setItem('last_page', "/Quest");
    localStorage.setItem('in_quest', "true");
    const navigate = useNavigate();
    let browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;
    let from_login=JSON.parse(localStorage.getItem('from_login'));

    if(from_login){
        saveProgress();
        localStorage.setItem('from_login', "false");
    }

    function updateCardstate(card_states,card_idx,state){
        card_states[card_idx]=state;
        const new_value=card_states;
        return new_value;
    }

    ///////////////
    //1. Navbar///
    /////////////
    const NavbarQuest=()=>{
        const navbar_sm=
        <Navbar bg="dark" expand="xxl" variant='dark'>
            <Container>    
            <Nav className='col-12 justify-content-end'>
                <NavLinks/>
            </Nav>
            </Container>          
        </Navbar>

        const navbar_lg=
        <Navbar bg="dark" expand="lg" variant='dark'>
            <Container>    
            <Nav className='col-12 justify-content-end'>
                <NavLinksLG/>
            </Nav>
            </Container>          
        </Navbar>

        let navbar=w<1024?navbar_sm:navbar_lg;
        return(navbar);
            }
    

    /////////////////////
    //1.1 NavbarLinks///
    ///////////////////
    const NavLinks=()=>{
        let username=session.username;

        const UsernameLabel=()=>{
            let username_label=
            <div class="col-2  bg-transparent text-white offset-end">
                <Button variant="dark" disabled>{username}</Button>
            </div> 

            let spinner_label=
            <div class="col-2 bg-transparent text-white offset-end">
                <Button variant="dark" disabled>
                    <label>{username}</label>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span className="visually-hidden"></span>
                </Button>
            </div>
            let display=saving?spinner_label:username_label;
            return (display);
        }

        
        const not_logged_navlinks=
        <div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse  id="basic-navbar-nav" >
          <Nav as="ul" onSelect={(eventKey)=>onItemSelected(eventKey)}>
            <Nav.Item as="li">
                <Nav.Link eventKey="signup-save">{labels.signup_to_save[lang]}</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
                <Nav.Link eventKey="login-save">{labels.login[lang]}</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
                <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        </div>
      
        const logged_navlinks=
        <div className='row'>
            <div class="col-10">
            <Navbar.Toggle aria-controls="basic-navbar-nav-logged" />
            <Navbar.Collapse  id="basic-navbar-nav-logged" >
            <Nav as="ul" onSelect={(eventKey)=>onItemSelected(eventKey)}>
                <Nav.Item as="li" >
                    <Nav.Link eventKey="save-progress">{labels.save_progress[lang]}</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" >
                    <Nav.Link eventKey="log-out-save">{labels.logout_save[lang]}</Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Nav.Link eventKey="log-out">{labels.logout[lang]}</Nav.Link>
                </Nav.Item>
            </Nav>
            </Navbar.Collapse>
            </div>
            <UsernameLabel/>
        </div>
      
      let display=logged? logged_navlinks : not_logged_navlinks;
      
      return (display);
      
      }
    ///////////////////////
    //1.1 NavbarLinksLG///
    /////////////////////

    const NavLinksLG=()=>{
            
        const SaveButton=()=>{
            let save_button= <button className='save-btn text-white' onClick={saveProgress}>{labels.save_progress[lang]}</button>
            let save_spinner=
            <Button bsPrefix='saving-btn text-white' disabled>
                <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                />
                <span className="visually-hidden">{labels.saving[lang]}</span>
            </Button>
            let display=saving?save_spinner:save_button;
            return (display);
        }

        let username=session.username;
        const navlinks_unlogged=
        <div className='row'>
            <div class="col-5 ">
                <button className='signup-quest-btn text-white' onClick={()=>{saveLocalProgress();navigate("/SignUp");}}>{labels.signup_to_save[lang]}</button>
            </div>
            <div class="col-5 ">
                <Nav.Link onClick={handleLinkClick}>{labels.login[lang]}</Nav.Link>
            </div>
            <div class="col-2 ">
                <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
            </div>
        </div>
            
        const navlinks_logged=
        <div className='row'>
            <div class="col-6 ">
                <SaveButton/>
            </div>
            <div class="col-2">
                <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
            </div> 
            <div class="col-4">
                <NavDropdown menuVariant="dark" onSelect={(eventKey)=>onItemSelected(eventKey)}  title={username} id="user-dropdown">
                    <NavDropdown.Item  eventKey="log-out-save">{labels.logout_save[lang]}</NavDropdown.Item>
                    <NavDropdown.Item  eventKey="log-out">{labels.logout[lang]}</NavDropdown.Item>
                </NavDropdown>
            </div>
        </div>
        var navlinks=logged? navlinks_logged : navlinks_unlogged;
        return(navlinks);
    }

    function onItemSelected(eventKey){
        switch (eventKey) {
          case "log-out":
            signOut();
            break;
          case "log-out-save":
            saveProgress();
            signOut();
            break;
          case "signup-save":
            saveLocalProgress();
            navigate("/SignUp")
            break;
          case "login-save":
            saveLocalProgress();
            navigate("/Login")
            break;
         case "save-progress":
            saveProgress();
            break;
        }
      }

    async function saveProgress(){
        let quest_key=session.quest_key;
        let username=session.username;
        let date= new Date().toLocaleDateString();
        let idx=session.idx;
        let session_dict={"quest_key":quest_key,"username":username,"saved_date":date,"queue":queue.current,"idx":idx,"episode":episode.current,"card_states":card_states}
        let session_str=JSON.stringify(session_dict);
        localStorage.setItem('session',session_str);
        setSaving(true);
        var response_put_session= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_session', 
                                {method: 'post',headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                },body: session_str}
                                )
        setSaving(false);
    }

    function saveLocalProgress(){
        let file_key=localStorage.getItem('file_key');
        let username=localStorage.getItem('username');
        let date= new Date().toLocaleDateString();
        let idx=session.idx;
        let session_dict={"quest_key":file_key,"username":username,"saved_date":date,"queue":queue.current,"idx":idx,"episode":episode.current,"card_states":card_states}
        let session_str=JSON.stringify(session_dict);
        localStorage.setItem('session',session_str);
    }
    

    function handleLinkClick() {
        saveLocalProgress();
        navigate('/LogIn'); 
    }
    
   
    const  signOut= useCallback(async () => {
        try {
            await Auth.signOut();
            setLogged(false);
            setTimeout(()=> {navigate("/");}, 1000);
        } catch (error) {
        }
    }, []);
      

    const Progress=()=>{
        var count = card_states.filter(item => item === 5.0).length;
        var percentage=Math.round(count/limit*100);
        var percentage_label=count.toString()+"/"+limit.toString();
            
        var display_progress=
            <div class="row justify-content-center px-5">
                <p class="col-12 offset-11 text-white">{percentage_label}</p>
                <div class="col-12 text-white" > 
                    <ProgressBar now={percentage} variant="success"/>
                </div>
            </div>
        
          
        return(display_progress);
    }
    //////////////////
    //2. Flashcard///
    ////////////////

    const Flascard=()=>{
        var card=queue.current[idx];
        var question=card[0];
        var answer=card[1];
        var options=card[2];
        var begin= new Date();
        var new_obs=[];
        var action={};
        var next_pos=0;
        var clock=0;
        var countdown=60;
        var new_state=1.0;
        var card_idx=card[3];


        var last_card=
        <div class="container-fluid">
            <div class="row justify-content-center p-5"> 
                <div class="card bg-dark text-white">
                    <div class="card-body">
                        <h5 class="card-title">{labels.question[lang]} {card_idx+1}</h5>
                        <p class="card-text">{question}</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center p-5">
                <div class="col-10 col-md-8 col-lg-4 ">
                    <input type="text" class="form-control w-100"  id="answer_input"></input>
                </div>
            </div>
            <div class="row justify-content-center py-5">
                <div class="col-5 col-md-4 col-lg-2 ">
                        <button onClick={evaluateAnswer} type="submit" class="btn btn-primary w-100">{labels.pass[lang]}</button>
                </div>
                <div class="col-5 col-md-4 col-lg-2 ">
                        <button onClick={evaluateAnswer} type="submit" class="btn btn-primary w-100">{labels.confirm_answer[lang]}</button>
                </div>
            </div>
            <div class="row justify-content-center px-5 pt-5">
                <div class="col-2 bg-transparent text-white d-flex justify-content-center fs-1" >
                    <p  class="bg-transparent text-white border-0" id="clock">60</p>
                </div>
            </div>
            <Progress/>
        </div>;

        var normal_card=
        <div class="container-fluid">
                    <div class="row justify-content-center p-1 p-md-5 p-lg-5"> 
                      <div class="card bg-dark text-white">
                            <div class="card-body">
                                <h5 class="card-title">{labels.question[lang]} {card_idx+1}</h5>
                                <p class="card-text">{question}</p>
                                <div class="row p-2 p-lg-5">
                                    <div class="col-5 col-lg-4 offset-lg-2">
                                        <button onClick={onSelectOption} type="button" class="btn btn-light">{options[0]}</button>
                                    </div>
                                    <div class="col-5 col-lg-4  offset-2">
                                        <button onClick={onSelectOption} type="button" class="btn btn-light">{options[1]}</button>
                                    </div>
                                </div>
                                <div class="row p-2 p-lg-5">
                                    <div class="col-5 col-lg-4  offset-lg-2">
                                        <button onClick={onSelectOption} type="button" class="btn btn-light">{options[2]}</button>
                                    </div>
                                    <div class="col-5 col-lg-4  offset-2">
                                        <button onClick={onSelectOption} type="button" class="btn btn-light">{options[3]}</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row justify-content-center px-5 pt-5">
                            <div class="col-2 bg-transparent text-white d-flex justify-content-center fs-1" >
                                <p  class="bg-transparent text-white border-0" id="clock">60</p>
                            </div>
                        </div>  
                    </div>
                <Progress/>
        </div>;


        function idxAdd(idx,limit,increase){
    
            var sum=idx+increase;
            var new_idx=sum;
            if(sum>=limit){
                new_idx=sum-limit;
            } else if(sum<0){
                new_idx=limit+sum;
            }
            
            return new_idx;
        }

        function get_action(new_obs){
            let step=32;
            let logpb=[];
            let pb=[];
            const do_policy = Math.random() < 0.7;
            try{
                if(do_policy){
                    let input = tf.tensor2d([new_obs]); 
                    let output = model.predict(input);
                    output.dataSync()[0] = 0.0;
                    pb= output.dataSync();
                    step = output.argMax(1).dataSync()[0];
                    logpb = pb.map(num => Math.log(num));
                }
                else{
                    step=Math.round(uniform.sample( a, b ));
                    pb=uniform_pdf;
                    logpb=uniform_logpb;
                }
            }
            catch (error) {
                step=Math.round(uniform.sample( a, b ));
                pb=uniform_pdf;
                logpb=uniform_logpb;
            }
            let action={'step':step,'pb':pb[step],'logpb':logpb[step]};
            return action;
        }

        async function saveEpisode(){
            const episode_str=JSON.stringify(episode.current);
            var response_training= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/training_data', 
            {method: 'post',headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },body: episode_str}
            )

        }

        var display_card=null;
        if(card_states[card_idx]===4.0){   
            display_card=last_card;
        }
        else{
            display_card=normal_card;
        }

    
        average_state=jStat.mean(card_states);
        if(average_state===5.0){
            saveProgress();
            saveEpisode();
            navigate("/Done")
        }

        if(card_states[card_idx]===5.0 && average_state!=5){   
            setIdx(idxAdd(idx,queue.current.length,1));
        }
    

        async function updateClock() {
            var now = new Date();
            if(average_state!=5.0){
                clock=now.getTime()-begin.getTime();
                clock=Math.floor(clock/1000);
                countdown=time_limit-clock+1;
                document.getElementById('clock').innerHTML = countdown;
            } 
            if(countdown===0){
                episode.current.t.push(idx);
                episode.current.eps_id.push(1);
                episode.current.agent_index.push(0);
                episode.current.unroll_id.push(1);
                episode.current.infos.push({});
                episode.current.rewards.push(0.0);
                if(idx==0){
                    episode.current.obs.push([1.0,1.0]);
                    episode.current.prev_rewards.push(0.0);
                    episode.current.prev_actions.push(1);
                }
                else{
                    episode.current.obs.push(episode.current.new_obs[idx-1]);
                    episode.current.prev_rewards.push(episode.current.rewards[idx-1]);
                    episode.current.prev_actions.push(episode.current.actions[idx-1]);
                }

                setCard_states(updateCardstate(card_states,card_idx,1.0));
                
                average_state=jStat.mean(card_states);
                new_obs=[card_states[card_idx],average_state];
                episode.current.new_obs.push(new_obs);
                action=get_action(new_obs);
                next_pos=idx+action.step;

                if(next_pos>queue.current.length-1){
                    queue.current.push(card);
                }
                else{
                    queue.current.splice(next_pos, 0, card);
                }
            
                episode.current.actions.push(action.step);
                episode.current.action_prob.push(action.pb);
                episode.current.action_logp.push(action.logpb);
               
                if(average_state===5.0){
                    episode.current.dones.push(true);
                }
                else{
                    episode.current.dones.push(false);
                }
                
                setIdx(idxAdd(idx,queue.current.length,1));
                begin= new Date();

                
                }
        }
        
        
        useEffect(() => {
            const timer=setInterval(updateClock, 1000);
            return () => clearInterval(timer);
        }, [updateClock]);
        

        function onSelectOption(e){
            episode.current.t.push(idx);
            episode.current.eps_id.push(1);
            episode.current.agent_index.push(0);
            episode.current.unroll_id.push(1);
            episode.current.infos.push({});
            
            if(idx==0){
                episode.current.obs.push([1.0,1.0]);
                episode.current.prev_rewards.push(0.0);
                episode.current.prev_actions.push(1);
            }
            else{
                episode.current.obs.push(episode.current.new_obs[idx-1]);
                episode.current.prev_rewards.push(episode.current.rewards[idx-1]);
                episode.current.prev_actions.push(episode.current.actions[idx-1]);
            }
            
            var selected=e.target.firstChild.data;
            if(selected===answer){
                if (clock>30){
                    new_state=2.0;
                }else if (clock>10){
                    new_state=3.0
                }else{
                    new_state=4.0;
                }
                setCard_states(updateCardstate(card_states,card_idx,new_state));
                e.target.className="btn btn-success";
                reward = parseFloat(countdown.toFixed(1));
            }
            else{
                setCard_states(updateCardstate(card_states,card_idx,1.0));
                e.target.className="btn btn-danger";
                reward=0.0;
            }

            average_state=jStat.mean(card_states);
            new_obs=[card_states[card_idx],average_state];
            episode.current.new_obs.push(new_obs)
            action=get_action(new_obs);            
            next_pos=idx+action.step;

            if(next_pos>queue.current.length-1){
                queue.current.push(card);
            }
            else{
                queue.current.splice(next_pos, 0, card);
            }

            episode.current.rewards.push(reward);
            episode.current.actions.push(action.step);
            episode.current.action_prob.push(action.pb);
            episode.current.action_logp.push(action.logpb);
            
            if(average_state===5.0){
                episode.current.dones.push(true);
            }
            else{
                episode.current.dones.push(false);
            }
            
            setTimeout(()=> {e.target.className="btn btn-light"}, 1000);
            setTimeout(()=> {setIdx(idxAdd(idx,queue.current.length,1))},1000);
            
        }
        
        function evaluateAnswer(){
            episode.current.t.push(idx);
            episode.current.eps_id.push(1);
            episode.current.agent_index.push(0);
            episode.current.unroll_id.push(1);
            episode.current.infos.push({});
            
            if(idx==0){
                episode.current.obs.push([1.0,1.0]);
                episode.current.prev_rewards.push(0.0);
                episode.current.prev_actions.push(1);
            }
            else{
                episode.current.obs.push(episode.current.new_obs[idx-1]);
                episode.current.prev_rewards.push(episode.current.rewards[idx-1]);
                episode.current.prev_actions.push(episode.current.actions[idx-1]);
            }

            let inputValue =document.getElementById("answer_input").value;
            inputValue = inputValue.toLowerCase()
            let answerLower = answer.toLowerCase();
            if(levenshtein(inputValue,answerLower)<=3){
                setCard_states(updateCardstate(card_states,card_idx,5.0));
                reward=200.0;
                average_state=jStat.mean(card_states);
                new_obs=[card_states[card_idx],average_state];
                action=get_action(new_obs);
            }
            else{
                setCard_states(updateCardstate(card_states,card_idx,1.0));
                reward=0.0;
                average_state=jStat.mean(card_states);
                new_obs=[card_states[card_idx],average_state];
                action=get_action(new_obs); 
                next_pos=idx+action.step;
                if(next_pos>queue.current.length-1){
                    queue.current.push(card)
                }
                else{
                    queue.current.splice(next_pos, 0, card);
                }
            }
            episode.current.rewards.push(reward);
            episode.current.actions.push(action.step);
            episode.current.action_prob.push(action.pb);
            episode.current.action_logp.push(action.logpb);
            episode.current.new_obs.push(new_obs);
            if(average_state===5.0){
                episode.current.dones.push(true);
            }
            else{
                episode.current.dones.push(false);
            }


            setTimeout(()=> {setIdx(idxAdd(idx,queue.current.length,1))},1000);
        }
        
        return(display_card);
    }

    return(
    <div class="container-fluid">
        <div class="container-fluid"> 
            <NavbarQuest/>
        </div>
        <div class="container-fluid">
            <Flascard/>
        </div>
    </div>  
    );
        
}




export default Quest;
