import { Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {useNavigate,Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import envelope_at_fill from './envelope-at-fill.svg';
import key from './key.svg';
import key_fill from './key-fill.svg';
import eye from './eye.svg';
import eye_slash from './eye-slash.svg';
import labels from './Labels';

const Account=()=>{
    var username= localStorage.getItem('username');
    var [success,setSuccess]=useState(false);
    const [showValidationCodeUI, setShowValidationCodeUI] = useState(false);
    const navigate = useNavigate();
    localStorage.setItem('last_page', "/Account");
    let browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;

    const PasswordOld = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="formPasswordOld">
                    <Form.Control type={showPassword ? "text" : "password"}/>
                    <Form.Label>{labels.current_password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };
    
    const PasswordNew = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="formPasswordNew">
                    <Form.Control type={showPassword ? "text" : "password"}/>
                    <Form.Label>{labels.enter_password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };
    
      const PasswordRepeat = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key_fill} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="formPasswordRepeat">
                    <Form.Control type={showPassword ? "text" : "password"}/>
                    <Form.Label>{labels.repeat_new_password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };
    
      async function updateUserEmail() {
        let new_email =document.getElementById("form_new_email").value;
        const user = await Auth.currentAuthenticatedUser();
      
        await Auth.updateUserAttributes(user, {
          email: new_email
        })
          .then(() => {
            console.log('');
          })
          .catch((e) => {
            console.log('');
          });
      }
      
      async function verifyEmailValidationCode(code) {
        await Auth.verifyCurrentUserAttributeSubmit('email', code)
          .then(() => {
            console.log('');
          })
          .catch((e) => {
            console.log('');
          });
      }
      
      function ValidationCodeForm() {
        const [validationCode, setValidationCode] = useState(null);
        return (
          <div>
            <label>
              {labels.put_code_email[lang]}  
              <input
                onChange={(e) => {
                  setValidationCode(e.target.value);
                }}
                type="text"
                name="vc"
              />
            </label>
            <button onClick={() => verifyEmailValidationCode(validationCode)}>
              {labels.send_code[lang]}
            </button>
          </div>
        );
      }
      
      async function deleteAccount() {
        let url_update='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/update_quests';
        let url_delete='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/delete_sessions'
        let response=null;

        try{
            response= await fetch(url_update+"/"+username, {method: 'get'});
            response= await response.json();
            response= await fetch(url_delete+"/"+username, {method: 'get'});
            response= await response.json();
            await Auth.deleteUser();
            document.getElementById("deleted_label").innerHTML=labels.account_deleted[lang];
            setTimeout(()=> {navigate("/");}, 1000);
        }
        catch (error) {
          }
      }
   

    const  validateForm = useCallback(async () => {
        var oldPassword =document.getElementById("formPasswordOld").value;
        var newPassword =document.getElementById("formPasswordNew").value;
        var repeat_password=document.getElementById("formPasswordRepeat").value;
        var info_label=document.getElementById("info_label");

        if(repeat_password===newPassword){
            try{
            let user = await Auth.currentAuthenticatedUser()
            let response= await Auth.changePassword(user, oldPassword, newPassword);
            console.log(response);
            setSuccess(true);
            info_label.innerHTML=labels.password_changed[lang];
            }
            catch (error) {
                console.log('error changing password:',error.name);
                if(error.name==="NotAuthorizedException"){
                    info_label.innerHTML=labels.password_not_current[lang];
                }
            }    
        }
        else{
            info_label.innerHTML=labels.new_password_no_match[lang];
        }
        }, []);

        
    const  signOut= useCallback(async () => {
        try {
                await Auth.signOut();
                localStorage.setItem('username',"No user");
                setTimeout(()=> {navigate("/");}, 1000);
        } catch (error) {
        }
    }, []);        

    const NavbarAccount=()=>{
        const navbar_diplay=
            <Navbar bg="dark" expand="xxl" variant='dark'>
                <Container>    
                    <Nav  className="col-12 justify-content-end">
                      <div class="col-2">
                            <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
                      </div>
                    </Nav>
                </Container>          
            </Navbar>
    
        return(navbar_diplay);
    }
    return(
    <div className='container-fluid'>
        <NavbarAccount/>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{labels.change_password[lang]}</Accordion.Header>
                <Accordion.Body>
                <PasswordOld/>
                <div class="d-flex mx-2 mb-3 mb-lg-4 ">
                    
                </div>
                <PasswordNew/>
                <div class="d-flex mx-2 mb-3 mb-lg-4 ">
                    <label>{labels.password_8_characters[lang]}</label>
                </div>
                <PasswordRepeat/>
                <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                    <label class={success?"text-success":"text-danger"} id="info_label"></label>
                </div>
                <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button type="button" onClick={validateForm} class="btn btn-primary btn-lg">{labels.change_password[lang]}</button>
                </div>                    
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>{labels.change_email[lang]}</Accordion.Header>
                <Accordion.Body>
                <Authenticator>
                    {({ signOut, user }) => (
                        <div>
                        <label class="ṕ-5">{labels.current_email[lang]}</label>
                        <label class="p-5">{user.attributes.email}</label>
                        <div class="d-flex flex-row mb-4">
                            <img src={envelope_at_fill} class="img-fluid me-3" ></img>
                            <div class="form-outline flex-fill mb-0">
                                <input type="email" id="form_new_email" class="form-control" />
                                <label class="form-label" for="form_new_email">{labels.new_email[lang]}</label>
                            </div>
                        </div>    
                        {showValidationCodeUI === false && (
                            <button class="btn btn-primary btn-lg" onClick={() => updateUserEmail() && setShowValidationCodeUI(true)}>
                                {labels.update_email[lang]}
                            </button>
                        )}
                        {showValidationCodeUI === true && <ValidationCodeForm />}
                        </div>
                    )}
                    </Authenticator>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>{labels.delete_account[lang]}</Accordion.Header>
                <Accordion.Body>
                <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button type="button" onClick={deleteAccount} class="btn btn-primary btn-lg">{labels.delete_account[lang]}</button>
                </div>
                <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                    <label>{labels.all_data_deleted[lang]}</label>
                </div>
                <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                    <label class="text-success" id="deleted_label"></label>
                </div>                    
                </Accordion.Body>
            </Accordion.Item>
    </Accordion>
    </div>
    );
}
export default Account;