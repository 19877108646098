import { Auth } from 'aws-amplify';
import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {redirect,useFetcher,useNavigate,Link} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import person_circle from './person-circle.svg';
import envelope_at_fill from './envelope-at-fill.svg';
import key from './key.svg';
import key_fill from './key-fill.svg';
import eye from './eye.svg';
import eye_slash from './eye-slash.svg';
import labels from './Labels';



const SignUp=()=>{
    const navigate = useNavigate();
    localStorage.setItem('last_page', "/SignUp");
    let browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;
    

    async function signUp() {
        var username =document.getElementById("form1").value;
        var email =document.getElementById("form2").value;
        var password =document.getElementById("formPassword").value;
        var repeat_password=document.getElementById("formPasswordRepeat").value;
        let signedUp=false;
        let browserLanguage = navigator.language || navigator.userLanguage;
        let lang=browserLanguage.slice(0,2)==='es'?1:0;
    
        if(repeat_password===password){
            try {
                const { user } = await Auth.signUp({
                    username,
                    password,
                    attributes: {email,},
                    autoSignIn: { enabled: true,}
                });
                
                localStorage.setItem('username', username);
                localStorage.setItem('email', email);
                signedUp=true;
            } catch (error) {
                console.log('error signing up:',error.name);
                if(error.name==="UsernameExistsException"){
                    document.getElementById("match_label").innerHTML=labels.username_already[lang];
                }
                if(error.name==="InvalidPasswordException"){
                    document.getElementById("match_label").innerHTML=labels.password_8_characters[lang];
                }
                if(error.name==="InvalidParameterException"){
                    document.getElementById("match_label").innerHTML=labels.email_invalid[lang];
                }
                
            }
        }
        else{
            var match_label=document.getElementById("match_label");
            match_label.innerHTML=labels.passwords_no_match[lang];
        }
        return (signedUp);
    }
    
    
    
    const PasswordControl = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Control type={showPassword ? "text" : "password"}/>
                    <Form.Label>{labels.password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };
    
      const PasswordRepeat = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key_fill} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="formPasswordRepeat">
                    <Form.Control type={showPassword ? "text" : "password"}/>
                    <Form.Label>{labels.repeat_password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };
    

    const  validateForm = useCallback(async () => {
        var signedUp = await signUp();
        if(signedUp){
            navigate("/ConfirmSignUp");
        }
      }, []);

    const signUp_form=
    <section class="vh-100" style={{"background-color": "#00061a"}}>
            <div class="container h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-lg-6 col-xl-6">
                    <div class="card text-black" style={{"border-radius": "25px"}}>
                    <div class="card-body p-md-5">
                        <div class="row">
                            <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">

                                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">{labels.signup[lang]}</p>

                                <form class="mx-1 mx-md-4">

                                    <div class="d-flex flex-row mb-4">
                                        <img src={person_circle} class="img-fluid me-3" ></img>
                                        <div class="form-outline flex-fill mb-0">    
                                        <input type="text" id="form1" class="form-control" />
                                        <label class="form-label" for="form1">{labels.your_username[lang]}</label>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-row mb-4">
                                        <img src={envelope_at_fill} class="img-fluid me-3" ></img>
                                        <div class="form-outline flex-fill mb-0">
                                        <input type="email" id="form2" class="form-control" />
                                        <label class="form-label" for="form2">{labels.your_email[lang]}</label>
                                        </div>
                                    </div>

                                    <PasswordControl/>
                                    <div class="d-flex mx-2 mb-3 mb-lg-4 ">
                                        <label>{labels.password_8_characters[lang]}</label>
                                    </div>
                                    <PasswordRepeat/>

                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-danger" id="match_label"></label>
                                    </div>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                        <button type="button" onClick={validateForm} class="btn btn-primary btn-lg">{labels.register[lang]}</button>
                                    </div>

                                </form>

                            </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
    
    return(signUp_form);
}

export default SignUp;