import { Auth } from 'aws-amplify';
import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import envelope_check from './envelope-check.svg';
import labels from './Labels';

const ConfirmSignUp=()=>{
    const navigate = useNavigate();
    localStorage.setItem('last_page', "/ConfirmSignUp");
    let browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;

    async function confirmSignUp() {
        var username=localStorage.getItem('username');
        var code =document.getElementById("formConfirmation").value;
        let confirmed=false;
        try {
          var response= await Auth.confirmSignUp(username, code, { forceAliasCreation: false });
          if(response==="SUCCESS"){
            confirmed=true;
    
          }
        } catch (error) {
            if(error.name==="CodeMismatchException"){
                var match_label=document.getElementById("match_label");
                match_label.innerHTML=labels.code_invalid[lang];
            }
        }
        return (confirmed);
    }
    
    async function resendConfirmationCode() {
        var username=localStorage.getItem('username');
        try {
            await Auth.resendSignUp(username);
            document.getElementById("resend_code_label").value=labels.code_resent[lang];
        } catch (err) {
        }
    }

    const  validateCode = useCallback(async () => {
        var confirmed = await confirmSignUp();
        if(confirmed){
            var match_label=document.getElementById("match_label");
            match_label.innerHTML=labels.succesfull_confirmation[lang];
            let username=localStorage.getItem('username');
            let email=localStorage.getItem('email');
            
            let user_dict={"username":username,"email":email};
            let user_str=JSON.stringify(user_dict);
            console.log(user_str);
            var response_put_user= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_user', 
                                    {method: 'post',headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                    },body: user_str}
                                    )
            
            setTimeout(()=> {navigate("/LogIn");}, 1000);
        }
        else{

        }
      }, []);

    const confirmation_form=
    <section class="vh-100" style={{"background-color": "#00061a"}}>
            <div class="container h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-lg-6 col-xl-6">
                    <div class="card text-black" style={{"border-radius": "25px"}}>
                    <div class="card-body p-md-5">
                        <div class="row">
                            <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">

                                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">{labels.confirmation[lang]}</p>

                                <form class="mx-1 mx-md-4">

                                    <div class="d-flex flex-row mb-4">
                                        <img src={envelope_check} class="img-fluid me-3" ></img>
                                        <div class="form-outline flex-fill mb-0">    
                                        <input type="text" id="formConfirmation" class="form-control" />
                                        <label class="form-label" for="formConfirmation">{labels.put_code[lang]}</label>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-success" id="match_label"></label>
                                    </div>

                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                        <button type="button" onClick={validateCode} class="btn btn-primary btn-lg">{labels.register[lang]}</button>
                                    </div>

                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-dark">{labels.not_code_received[lang]}</label>
                                        <button onClick={resendConfirmationCode} class="btn btn-primary btn-lg offset-1">{labels.resend_code[lang]}</button>
                                    </div>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-success" id="resend_code_label"></label>   
                                    </div>

                                </form>

                            </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    </section>
    
    return(confirmation_form);
}

export default ConfirmSignUp; 