import labels from './Labels';
import {useNavigate} from 'react-router-dom';



const Done=()=>{
    const session=JSON.parse(localStorage.getItem('session'));
    const episode=session.episode;
    const question_flashcards=episode.t.length;
    const quest = JSON.parse(localStorage.getItem('questionnaire')); 
    const questionnaire=quest.questionnaire;
    const num_questions=questionnaire.length;
    const ratio=question_flashcards/num_questions;
    const browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;
    const navigate = useNavigate();
    localStorage.setItem('last_page', "/Done");

    let card_text_1=labels.num_questions[lang]+num_questions.toString();
    let card_text_2=labels.num_flashcards[lang]+question_flashcards.toString();
    let card_text_3=labels.ratio[lang]+ratio.toFixed(2);

    async function restart(){
        let date= new Date().toLocaleDateString();
        let queue=questionnaire;
        for(const key of queue.keys()){queue[key]=queue[key].concat([key]);};
        let idx=0;
        let episode={"t":[],"eps_id":[],"agent_index":[],"obs":[],"actions":[],"action_prob":[],"action_logp":[],"rewards":[],
                "prev_actions":[],"prev_rewards":[],"dones":[],"infos":[],"new_obs":[],"unroll_id":[]};
        let card_states= Array.from({length: questionnaire.length}, () => 1.0)
        let session_dict={"quest_key":session.quest_key,"username":session.username,"saved_date":date,"queue":queue,"idx":idx,"episode":episode,"card_states":card_states}
        let session_str=JSON.stringify(session_dict);
        debugger;
        localStorage.setItem('session',session_str);
        let response_put_session= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_session', 
                                        {method: 'post',headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json'
                                        },body: session_str}
                                        )
        navigate('/Quest');
    }


    return(
        <div class="container-fluid">
            <div class="row justify-content-center p-5"> 
                <div class="col-8 col-md-4 col-lg-2 bg-transparent text-white text-center fs-1" >
                    <label>{labels.done[lang]}</label>
                </div>
            </div>
            <div class="row justify-content-center p-5"> 
                <div class="col-8 col-md-4 col-lg-2 bg-transparent text-white text-center fs-1" >
                    <label>{labels.congratulations[lang]}</label>
                </div>
            </div>
            <div class="row justify-content-center p-5"> 
                <div class="card bg-dark text-white">
                    <div class="card-body">
                        <h5 class="card-title fs-3">{labels.performance[lang]}</h5>
                        <p class="card-text">{card_text_1}</p>
                        <p class="card-text">:{card_text_2}</p>
                        <p class="card-text">{card_text_3}</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center p-5"> 
                <div class="col-8 col-md-4 col-lg-2 bg-transparent text-white d-flex justify-content-center fs-1" >
                    <button onClick={() => navigate('/')} type="button" class="btn btn-success w-100">{labels.back_home[lang]}</button>
                </div>
            </div>
            <div class="row justify-content-center p-5"> 
                <div class="col-12 bg-transparent text-white d-flex justify-content-center" >
                    {labels.to_restart[lang]}
                </div>
            </div>
            <div class="row justify-content-center p-5"> 
                <div class="col-6 col-md-4 col-lg-2 bg-transparent d-flex justify-content-center fs-1" >
                    <button type="button" onClick={restart} class="restart-btn btn btn-success w-100 text-white">{labels.restart[lang]}</button>
                </div>
            </div>  
        </div>
    );
}

export default Done;
