import { Auth } from 'aws-amplify';
import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {redirect,useFetcher,useNavigate,Link,useHistory} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import person_circle from './person-circle.svg';
import key from './key.svg';
import eye from './eye.svg';
import eye_slash from './eye-slash.svg';
import labels from './Labels';

const LogIn=()=>{
    const navigate = useNavigate();
    localStorage.setItem('last_page', "/LogIn");
    let in_quest=JSON.parse(localStorage.getItem('in_quest'));
    let browserLanguage = navigator.language || navigator.userLanguage;
    let lang=browserLanguage.slice(0,2)==='es'?1:0;

    function setDefaulttUsername() {
        let username=localStorage.getItem('username');
        let from_recovery=JSON.parse(localStorage.getItem('from_recovery'));
        if(from_recovery){
            document.getElementById("form-username").value=username;
            localStorage.setItem('from_recovery', "false");
        }
    }

    async function signIn() {
        var username =document.getElementById("form-username").value;
        var password =document.getElementById("form-password").value;
        var login=false;
        try {
            const user = await Auth.signIn(username, password);
            login=true;
        } catch (error) {
            var match_label=document.getElementById("match_label");
            if(error.name==="UserNotFoundException"){
                match_label.innerHTML=labels.not_registered[lang];
            }
            if(error.name==="NotAuthorizedException"){
                match_label.innerHTML=labels.password_not_correct[lang];
            }
            if(error.name==="AuthError"){
                match_label.innerHTML=labels.username_not_specified[lang];
            }
            if(error.name==="InvalidParameterException"){
                match_label.innerHTML=labels.password_not_specified[lang];
            }
            
        }
        return(login);
    }
    
    const PasswordControl = () => {
        const [showPassword, setShowPassword] = useState(false);
      
        return (
            <div class="d-flex flex-row  mb-4">
                <img src={key} class="img-fluid me-3" ></img>
                <Form.Group className="mb-3" controlId="form-password">
                    <Form.Control type={showPassword ? "text" : "password"} />
                    <Form.Label>{labels.password[lang]}</Form.Label>
                </Form.Group>
                <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                    <img src={showPassword ? eye_slash:eye} class="img-fluid" ></img>
                </Button> 
            </div>                                   
        );
      };

    const  validateLogin = useCallback(async () => {
        var match_label=document.getElementById("match_label");
        match_label.innerHTML="";
        let login = await signIn();
        let username =document.getElementById("form-username").value;
        if(login){
            localStorage.setItem('username', username);
            document.getElementById("logged_label").innerHTML=labels.login_succesful[lang];
            if (in_quest){
                let session_dict=JSON.parse(localStorage.getItem('session'));
                session_dict.username=username;
                let session_str=JSON.stringify(session_dict);
                localStorage.setItem('session',session_str);
                let quest_dict=JSON.parse(localStorage.getItem('questionnaire'));
                quest_dict.username=username;
                let quest_str=JSON.stringify(quest_dict);
                localStorage.setItem('questionnaire',quest_str);
                var response_put_quest= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_quest', 
                                {method: 'post',headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                },body: quest_str}
                                )
                localStorage.setItem('from_login', "true");
                navigate("/Quest");
            }
            else{
                setTimeout(()=> {navigate("/");}, 1000);
            }
        }
      }, []);


    const logIn_form=
    <body onLoad={setDefaulttUsername}>
    <section class="vh-100" style={{"background-color": "#00061a"}}>
            <div class="container h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-lg-6 col-xl-6">
                    <div class="card text-black" style={{"border-radius": "25px"}}>
                    <div class="card-body p-md-5">
                        <div class="row">
                            <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">

                                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">{labels.login[lang]}</p>

                                <form class="mx-1 mx-md-4">

                                    <div class="d-flex flex-row mb-4">
                                        <img src={person_circle} class="img-fluid me-3" ></img>
                                        <div class="form-outline flex-fill mb-0">
                                        <input type="text" id="form-username" class="form-control" />
                                        <label class="form-label" for="form-username">{labels.username[lang]}</label>
                                        </div>
                                    </div>
                                    <PasswordControl/>

                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-danger" id="match_label"></label>
                                    </div>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-success" id="logged_label"></label>
                                    </div>


                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                        <button onClick={validateLogin} type="button" class="btn btn-primary btn-lg">{labels.login[lang]}</button>
                                    </div>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-dark" >{labels.remember_password[lang]}</label>
                                        <Link class="offset-1" to="/AccountRecovery" replace>{labels.recover_account[lang]}</Link>
                                    </div>
                                    <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                                        <label class="text-dark" >{labels.offer_signup[lang]}</label>
                                        <Link class="offset-1" to="/SignUp" replace>{labels.signup[lang]}</Link>
                                    </div>
                                </form>

                            </div>
                        
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    </section>
    </body>

    return(logIn_form);
}

export default LogIn;