import React, { Component, createContext ,useCallback, useState, useEffect,useRef} from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import awsExports from "./aws-exports";
import awsconfig from './aws-exports';
import Amplify, { Storage }  from 'aws-amplify';
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import {BrowserRouter as Router,Routes,Route,Link,useNavigate,redirect} from 'react-router-dom';
import questContext from "./components/questContext";
import Quest from "./components/Quest";
import SignUp from "./components/SignUp";
import LogIn from "./components/LogIn";
import Account from "./components/Account";
import ConfirmSignUp from "./components/ConfirmSIgnUp";
import AccountRecovery from "./components/AccountRecovery";
import LoadSession from "./components/LoadSession";
import Done from "./components/Done";
import logo_rec from './logo-rec.svg';
import labels from './components/Labels';

Amplify.configure(awsExports);
Amplify.configure(awsconfig);


 const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
});


var socket = new WebSocket("wss://11jh8j05e2.execute-api.us-east-1.amazonaws.com/api/");
var file_to_upload=null; 

const Home=()=>{
  var [logged,setLogged]=useState(false);  
  var [file_selected,setFile_selected]=useState(false);
  var [status,setStatus]=useState(null);
  var succeeded=useRef(false);
  var [count,setCount]=useState(0);
  const navigate = useNavigate();
  localStorage.setItem('last_page', "/");
  localStorage.setItem('in_quest', "false");
  localStorage.setItem('from_recovery', "false");
  let browserLanguage = navigator.language || navigator.userLanguage;
  let lang=browserLanguage.slice(0,2)==='es'?1:0;

  var data=null;
  socket.onmessage = (event) => {  
    data=event.data
    if (succeeded.current){  
      localStorage.setItem('questionnaire', data);
      put_session();
      put_quest();
      setStatus('SUCCEEDED');
    }
    else{
      switch (data) {
        case 'SUCCEEDED':
          succeeded.current=true;
          break;
        case 'RUNNING':
          setStatus('RUNNING');
          setCount(count+1)
          break;
        case 'NUM_PAGES':
          setStatus('NUM_PAGES');
          break;
        case 'FAILED':
          setStatus('FAILED');
          break;
      }
      
    }
  }
  
  const  userInfo = useCallback(async () => {
    try{
    let user = await Auth.currentAuthenticatedUser();
    setLogged(true);
    localStorage.setItem('username', user.username);
  }
  catch (error) {
    setLogged(false);
    localStorage.setItem('username', "No user");
  }  

}, []);
userInfo();



async function put_quest(){
  let  questionnaire = JSON.parse(data).result;
  let username=localStorage.getItem('username');
  let file_key=file_to_upload.name.split(".pdf")[0];
  localStorage.setItem('file_key', file_key);
  let date= new Date().toLocaleDateString();
  let quest_dict={"quest_key":file_key,"username":username,"created_date":date,"questionnaire":questionnaire};
  let quest_str=JSON.stringify(quest_dict);
  localStorage.setItem('questionnaire',quest_str);
  var response_put_quest= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_quest', 
                                {method: 'post',headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                                },body: quest_str}
                                )
  localStorage.setItem('questionnaire',quest_str);  
}

async function put_session(){
  let questionnaire = JSON.parse(data).result;
  let file_key=file_to_upload.name.split(".pdf")[0];
  let username=localStorage.getItem('username');
  let date= new Date().toLocaleDateString();
  let queue=questionnaire;
  for(const key of queue.keys()){queue[key]=queue[key].concat([key]);};
  let idx=0;
  let episode={"t":[],"eps_id":[],"agent_index":[],"obs":[],"actions":[],"action_prob":[],"action_logp":[],"rewards":[],
        "prev_actions":[],"prev_rewards":[],"dones":[],"infos":[],"new_obs":[],"unroll_id":[]};
  let card_states= Array.from({length: questionnaire.length}, () => 1.0)
  let session_dict={"quest_key":file_key,"username":username,"saved_date":date,"queue":queue,"idx":idx,"episode":episode,"card_states":card_states}
  console.log(session_dict);
  let session_str=JSON.stringify(session_dict);
  localStorage.setItem('session',session_str );
  var response_put_session= await fetch('https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/put_session', 
                                  {method: 'post',headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json'
                                  },body: session_str}
                                  )

}

const signOut=useCallback(async () => {
  try {
      await Auth.signOut();
      setLogged(false);
  } catch (error) {
  }
}, []);

function onItemSelected(eventKey){
  switch (eventKey) {
    case "log-out":
      console.log(eventKey);
      signOut();
      break;
    case "account":
      console.log(eventKey);
      navigate("/Account");
      break;      
  }
}



const onChange=useCallback(async (e) => {
  socket = new WebSocket("wss://11jh8j05e2.execute-api.us-east-1.amazonaws.com/api/");
  
  file_to_upload = e.target.files[0];
  
  setFile_selected(true);
}, []);

const uploadFile=useCallback(async () => {
  setStatus("UPLOADING");
  const file=file_to_upload;
  try {
      let key= await Storage.put(file.name, file, {
      contentType: "application/pdf", 
      });
    socket.send(key['key']);
  } catch (error) {
  }
},[]);



///////////////
//1. Navbar///
/////////////
const NavbarHome=()=>{
  var w = window.innerWidth;
  var h = window.innerHeight;

  const navbar_sm=
  <Navbar bg="dark" expand="xxl" variant='dark'>
    <Container>    
      <Nav className='col-12 justify-content-end'>
          <NavLinks/>
      </Nav>
    </Container>          
  </Navbar>

  const navbar_lg=
  <Navbar bg="dark" expand="lg" variant='dark'>
    <Container>    
      <Nav className='col-12 justify-content-end'>
          <NavLinksLG/>
      </Nav>
    </Container>          
  </Navbar>

  let navbar=w<1024 && w<h?navbar_sm:navbar_lg;
  return(navbar);
}


const NavLinks=()=>{
  let username=localStorage.getItem('username')
  const not_logged_navlinks=
  <div>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse  id="basic-navbar-nav" >
    <Nav as="ul">
      <Nav.Item as="li">
          <Nav.Link onClick={() => navigate('/SignUp')}>{labels.signup[lang]}</Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
          <Nav.Link onClick={() => navigate('/LogIn')}>{labels.login[lang]}</Nav.Link>
      </Nav.Item>
    </Nav>
  </Navbar.Collapse>
  </div>

  const logged_navlinks=
  <div className='row'>
  <div class="col-10">
  <Navbar.Toggle aria-controls="basic-navbar-nav-logged" />
  <Navbar.Collapse  id="basic-navbar-nav-logged" >
    <Nav as="ul" onSelect={(eventKey)=>onItemSelected(eventKey)}>
      <Nav.Item as="li">
        <Nav.Link onClick={() => navigate('/LoadSession')}>{labels.loadprogress[lang]}</Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link onClick={() => navigate('/Account')}>{labels.account[lang]}</Nav.Link>
      </Nav.Item>
      <Nav.Item as="li">
        <Nav.Link eventKey="log-out">{labels.logout[lang]}</Nav.Link>
      </Nav.Item>
    </Nav>
  </Navbar.Collapse>
  </div>
  <div class="col-2 bg-transparent text-white offset-end">
      <label>{username}</label>
  </div>
  </div>

let display=logged? logged_navlinks : not_logged_navlinks;

return (display);

}

const NavLinksLG=()=>{
  let username=localStorage.getItem('username')
  const not_logged_navlinks=
  <div className='row'>
    <div class="col-5 ">
      <button className='signup-btn text-white' onClick={()=>{navigate("/SignUp");}}>{labels.signup[lang]}</button>
    </div>
    <div class="col-7">
      <Nav.Link onClick={() => navigate('/LogIn')}>{labels.login[lang]}</Nav.Link>
    </div>
  </div>

  const logged_navlinks=
  <div className='row'>
    <div class="col-9">
      <button className='load-progress-btn text-white' onClick={()=>{navigate("/LoadSession");}}>{labels.loadprogress[lang]}</button>
    </div>
    <div class="col-3">
      <NavDropdown menuVariant="dark" onSelect={(eventKey)=>onItemSelected(eventKey)}  title={username} id="user-dropdown">
            <NavDropdown.Item  eventKey="account">{labels.account[lang]}</NavDropdown.Item>
            <NavDropdown.Item  eventKey="log-out">{labels.logout[lang]}</NavDropdown.Item>
      </NavDropdown>
    </div>
  </div>

let display=logged? logged_navlinks : not_logged_navlinks;

return (display);
}

//////////////////////
//2. Message Label///
////////////////////
const MessageLabel=()=>{
  
let display_message=labels.default_message[lang];
switch (status) {
  case 'UPLOADING':
    display_message=labels.uploading_message[lang];
    break;
  case 'SUCCEEDED':
    display_message=labels.ready_message[lang];
    break;
  case 'RUNNING':
    display_message=labels.processing_message[lang];
    break;
  case 'NUM_PAGES':
    display_message=labels.limit_pages_message[lang];
    break;
  case 'FAILED':
    display_message=labels.failed_message[lang];
    break;
  default:
    display_message=labels.default_message[lang];
}

var message=
<div class="row justify-content-center px-lg-5">
  <div class="col-10 col-md-8 col-lg-6 text-white" > 
    <p>{display_message}</p>
  </div>
</div>

  return(message);
}

/////////////////////
//3. Progress Bar///
///////////////////
const Progress=()=>{
  const cuts=[0,17,34,51,68,85,100];
  var percentage=0;
  percentage=!succeeded.current && count<=6 ? percentage=cuts[count] : 100;
  var display_nothing=
  <div class="row justify-content-center ">
    <div> </div>
  </div>

  var display_progress=
  <div class="row justify-content-center">
    <div class="col-10 col-md-8  col-lg-6 text-white" > 
      <ProgressBar className="w-100" animated now={percentage}/>
    </div>
  </div>
  display_progress=status==="RUNNING"?display_progress:display_nothing;

  return(display_progress);
}

/////////////////////
//4. Load Button////
///////////////////
const LoadButton=(props)=>{
  const not_selected_button=
  <div class="col-4 col-md-4 col-lg-2">
    <button disabled={true}  onClick={uploadFile} type="button" class="btn btn-secondary w-100" id="load_btn">{labels.make_flashcards[lang]}</button>
  </div>

  const selected_button=
  <div class="col-4 col-md-4 col-lg-2">
    <button onClick={uploadFile} type="button" class="btn btn-success w-100" id="load_btn">{labels.make_flashcards[lang]}</button>
  </div>

  const processing_button=
  <div class="col-4 col-md-4 col-lg-2">
    <Link to="/Quest" replace>
      <button disabled={true} type="button" class="btn btn-secondary w-100" id='quest_btn'>{labels.start[lang]}</button>
    </Link>
  </div>

const succeeded_button=
  <div class="col-4 col-md-4 col-lg-2">
    <Link to="/Quest" replace>
      <button type="button" class="btn btn-success w-100" id='quest_btn'>{labels.start[lang]}</button>
    </Link>
  </div>

  const uploading_spinner=
  <div class="col-4 col-md-4 col-lg-2">
    <Button className="btn btn-secondary w-100" disabled>
                <Spinner
                as="span"
                animation="grow"
                size="lg"
                role="status"
                aria-hidden="true"
                />
                <span className="visually-hidden">{labels.processing[lang]}</span>
    </Button>
  </div>

  var display_button=props.disabled? not_selected_button : selected_button;
  display_button=status==="UPLOADING"? uploading_spinner : display_button;
  display_button=status==="RUNNING"? processing_button : display_button;
  display_button=status==="SUCCEEDED"? succeeded_button : display_button;

  return (display_button);
}


const Foot = () => {
  const messages = [
    labels.automatically[lang],
    labels.answer[lang],
    labels.test_knowledge[lang],
    labels.leitner_system[lang],
    labels.let_ai[lang]
  ];
  
  let [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  let message = messages[currentMessageIndex];


  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentMessageIndex(currentMessageIndex === messages.length-1?0:currentMessageIndex + 1);
    }, 5000); 
      
    return () => {
      clearTimeout(timer);
    };
    
  }, [currentMessageIndex]);

  return (
    <div className="row justify-content-center">
      <div className="col-lg-8 offset-lg-2 text-md text-sm text-xs-sm  text-white">
          <div className="message-animation" key={currentMessageIndex}>
            {message}
          </div>
      </div>
    </div>
  );
};

  return (
  <div>
    <NavbarHome/>
    <div class="container-fluid" >

      <div class="row justify-content-center py-5 p-lg-5">
        <div class="col-8 col-lg-4"> 
          <img src={logo_rec} class="img-fluid" alt="QUESTIONARIA"></img>
        </div>
      </div>

      <MessageLabel/>
      <Progress/>
      
      <div class="row justify-content-center py-5"> 
        <div class="col-10 col-md-8  col-lg-6 "> 
          <input class="form-control" type="file" accept=".pdf" onChange={onChange} id="input_file"/>
        </div>
      </div>
      <div class="row justify-content-center py-5 p-lg-5"> 
        <LoadButton disabled={!file_selected}/>
      </div>
      <Foot/>
    </div>
    
  </div>
    );
}

class App extends Component {
  
  render() {
    return (
      <questContext.Provider value="">
       <Router>
        
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/Quest" element={<Quest/>} />
            <Route path="/SignUp" element={<SignUp/>} />
            <Route path="/LogIn" element={<LogIn/>} />
            <Route path="/ConfirmSignUp" element={<ConfirmSignUp/>} />
            <Route path="/AccountRecovery" element={<AccountRecovery/>} /> 
            <Route path="/LoadSession" element={<LoadSession/>} />
            <Route path="/Account" element={<Account/>} />
            <Route path="/Done" element={<Done/>} />
          </Routes>
         
       </Router>
      </questContext.Provider>
    );
  }
}


export default App;
