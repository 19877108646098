import React, { Component ,useContext, useState, useEffect, useRef ,useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import labels from './Labels';


function LoadSession() {
  var [loaded,setLoaded]=useState(false);
  var items=useRef([]);
  const navigate = useNavigate();
  localStorage.setItem('last_page', "/LoadSession");
  let browserLanguage = navigator.language || navigator.userLanguage;
  let lang=browserLanguage.slice(0,2)==='es'?1:0;
  
  async function getQuestList(){
    
    let username=localStorage.getItem('username');
    let url='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/get_quests'
    if (!loaded){
      try{
        let response= await fetch(url+"/"+username, {method: 'get'})
        response= await response.json();
        items.current=response;
        setLoaded(true);
      }
      catch (error) {
      }
    }
  }

  getQuestList();

  async function getQuest(key){
    let url='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/get_quest'
    try{
      let response= await fetch(url+"/"+key, {method: 'get'});
      let quest= await response.json();
      let quest_str=JSON.stringify(quest);
      localStorage.setItem('questionnaire',quest_str);
    }
    catch (error) {
    }
  
  }
  async function loadProgress(quest_key){
    var session={};
    let username=localStorage.getItem('username');
    let session_key={"quest_key":quest_key,"username":username};
    session_key=JSON.stringify(session_key);
    let url='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/get_session'
    getQuest(session_key);
    try{
      let response= await fetch(url+"/"+session_key, {method: 'get'});
      session= await response.json();
      let session_str=JSON.stringify(session);
      localStorage.setItem('session',session_str);
      setTimeout(()=> {navigate("/Quest");}, 1000);
    }
    catch (error) {
    }
  }
  
  const NavbarLoad=()=>{
    const navbar_diplay=
        <Navbar bg="dark" expand="xxl" variant='dark'>
            <Container>    
                <Nav  className="col-12 justify-content-end">
                  <Nav.Link onClick={() => navigate('/')}>{labels.home[lang]}</Nav.Link>
                </Nav>
            </Container>          
        </Navbar>

    return(navbar_diplay);
}


  const LoadedList=()=>{
  
    return(
    <div class="container-fluid">
      <NavbarLoad/>
      <ol class="list-group list-group-numbered">
        {items.current.map(item => {
          return(<button type="button" onClick={()=>{loadProgress(item)}} class="list-group-item list-group-item-action text-white">{item}</button>);
        })}
      </ol> 
    </div>
    );
  }


  var display_spinner=
  <div class="container-fluid">
    <div class="row justify-content-center pt-5">
      <div class="col-2">
        <Spinner animation="grow" />
      </div>
    </div>
  </div>
  

  
  var display=loaded? <LoadedList/>: display_spinner;

  return (display);

}

export default LoadSession;