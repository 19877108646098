const labels=
{
    'signup':['SignUp','Registrarse'],
    'login':['LogIn','Iniciar sesión'],
    'loadprogress':['Load previous progress','Cargar progreso previo'],
    'account':['Account','Mi cuenta'],
    'logout':['LogOut','Cerrar sesión'],
    'make_flashcards':['Make flascards','Crear flascards'],
    'start':['Start','Comenzar'],
    'processing':['Processing...','Procesando...'],
    'default_message':["Select a PDF file with a maximum of 1000 pages","Selecciona un archivo PDF con un máximo de 1000 páginas"],
    'uploading_message':["Checking and uploading file...","Comprobando y subiendo archivo..."],
    'ready_message':["Flashcards ready!","Flashcards listas!"],
    'processing_message':["Processing file...","Procesando archivo..."],
    'limit_pages_message':["Error: File has more than 1000 pages","Error: El archivo tiene más de 1000 páginas"],
    'failed_message':["An error ocurred processing your file. Try again later.",
    "Un error ocurrió mientras mientras se procesaba su archivo, Pruebe de nuevo más tarde."],
    'signup_to_save':['SignUp','Registrarse'],
    'home':['Home','Inicio'],
    'save_progress':['Save progress','Guardar progreso'],
    'logout_save':['Log out and save progress','Cerrar sesión y guardar progreso'],
    'saving':['Saving...','Guardando...'],
    'question':['Question','Pregunta'],
    'pass':['Pass','Ignorar'],
    'confirm_answer':['Confirm answer','Confirmar respuesta'],
    'num_questions':['Number of questions: ','Número de preguntas: '],
    'num_flashcards':['Number of flahcards displayed: ','Número de flashcards mostradas: '],
    'ratio':['Ratio flashcards/question: ','Ratio flashcards/preguntas: '],
    'done':['Done','Hecho'],
    'congratulations':['Congratulations!!!','Felicidades!!!'],
    'performance':['Performance:','Rendimiento:'],
    'back_home':['Back Home','Volver al Inicio'],
    'username_already':['Username already exists. Choose another.','Nombre de usuario ya existe. Elija otro.'],
    'password_8_characters':['Password must have at least 8 characters','Su contraseña debe tener al menos 8 carácteres'],
    'email_invalid':['Invalid email direction format','Formato de email no válido'],
    'passwords_no_match':["Passwords don't match",'Las contraseñas no coinciden'],
    'password':['Password','Contraseña'],
    'repeat_password':['Repeat your password','Vuelve a escribir tu contraseña'],
    'your_username':['Your Username','Tu nombre de usuario'],
    'your_email':['Your Email','Tu correo electrónico'],
    'register':['Register','Registrarse'],
    'code_invalid':['Code you entered is invalid','El código ingresado no es válido'],
    'code_resent':['Code resent successfully','Código reenviado exitosamente'],
    'succesfull_confirmation':['Successful code confirmation','Confirmación de código exitosa'],
    'confirmation':['Confirmation','Confirmación'],
    'put_code':['Put the 6 digit code you received in your email','Ingresa el código de 6 digitos que recibiste en tu cuenta de correo'],
    'not_code_received':["Don't received confirmation code?",'No recibiste el código de confirmación?'],
    'resend_code':['Resend code','Reenviar'],
    'username':['Username','Nombre de usuario'],
    'not_registered':['Username is not registered in the platform','Nombre de usuario no registrado en la plataforma'],
    'password_not_correct':['Password is not correct','La contraseña no es correcta'],
    'username_not_specified':['Username not specified','Nombre de usuario no especificado'],
    'password_not_specified':['Password not specified','Contraseña no especificada'],
    'remember_password':["Don't remember your password?",'No recuerdas tu contraseña?'],
    'login_succesful':['Log in successful','Inicio de sesión exitoso'],
    'offer_signup':['If you are not a registered user you can:','Si no eres un usuario registrado puedes:'],
    'recover_account':['Recover account','Recuperar cuenta'],
    'enter_password':['Enter new password','Introduzca nueva contraseña'],
    'repeat_new_password':['Repeat your new password','Repita la nueva contraseña'],
    'put_code_email':['Put the 6 digit code you received in your email address:','Ingresa el código de 6 digitos que recibiste en tu cuentra de correo:'],
    'send_code':['Send Code','Enviar código'],
    'password_changed':['Password changed successfully','Contraseña cambiada exitosamente'],
    'password_not_current':['Password you entered is not current password','La contraseña introducida no es la contraseña actual'],
    'new_password_no_match':['New password does not match','Nueva contraseña no coincide'],
    'change_password':['Change password','Cambiar contraseña'],
    'change_email':['Change email','Cambiar dirección de correo'],
    'current_password':['Enter current password','Introduzca su contraseña actual'],
    'current_email':['Current email address:','Dirección de correo actual:'],
    'new_email':['New email address','Nueva dirección de correo'],
    'update_email':['Update email','Actualizar dirección de correo'],
    'account_recovered':['Account recovered succesfully!','Cuenta recuperada exitosamente!'],
    'new_password':['New password','Nueva contraseña'],
    'enter_email_reestablish':['Enter your email. We will send a verification code to reestablish your account.',
    'Ingresa tu dirección de correo. Te enviaremos un código de verificación para reestablecer tu cuenta.'],
    'email_not_registered':['Email address not registered in the platform','Correo electrónico no registrado en la plataforma'],
    'send_verification_code':['Send verification code','Enviar código de verificación'],
    'verify_code':['Verify code','Verificar código'],
    'delete_account':['Delete account','Eliminar cuenta'],
    'all_data_deleted':['All your data on the platform will be deleted','Se eliminarán todos sus datos en la plataforma'],
    'account_deleted':['Account deleted','Cuenta eliminada'],
    'automatically':['Automatically generate flashcards instead of making them manually',
    'Genera flashcards automáticamente en lugar de hacerlo manualmente'],
    'answer':['Answer fill in the blank questions in spaced repetitive study sessions',
    'Responde preguntas de completar espacio en blanco en sesiones de repetición espaciada'],
    'test_knowledge':['Test your knowledge on a given study material','Pon a prueba tu conocimiento en un material de estudio dado'],
    'leitner_system':['Do you like the Leitner system?','¿Te gusta el método Leitner?'],
    'let_ai':['Let an AI model decide the frequency to show a flashcard','Deja que un modelo de IA elija la frecuencia para mostrarte una flashcard'],
    'to_restart':['To restart studying this material:','Para volver a estudiar este material:'],
    'restart':['Restart','Reiniciar']
    }


export default labels;