import Spinner from 'react-bootstrap/Spinner';
import { Auth } from "@aws-amplify/auth";
import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import envelope_at_fill from './envelope-at-fill.svg';
import envelope_check from './envelope-check.svg';
import Button from 'react-bootstrap/Button';
import eye from './eye.svg';
import eye_slash from './eye-slash.svg';
import key from './key.svg';
import labels from './Labels';

const AccountRecovery=()=>{
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState(null);
  const [isRecoveringAccount, setIsRecoveringAccount] = useState(false);
  let validationCode=null;
  const navigate = useNavigate();
  localStorage.setItem('last_page', "/AccountRecovery");
  let browserLanguage = navigator.language || navigator.userLanguage;
  let lang=browserLanguage.slice(0,2)==='es'?1:0;
   


  async function recoverAccount(username) {
      try{
          Auth.forgotPassword(username);
        }
        catch (error) {
        }
  }

  async function verifyEmailValidationCode(username,code) {
    let new_password=document.getElementById("formPassword").value ?? "";
    let verified_label=document.getElementById("verified_label");
    let no_verified_label=document.getElementById("no_verified_label");
  
    try{
      await Auth.forgotPasswordSubmit(username, code, new_password)
      verified_label.innerHTML=labels.account_recovered[lang];
      localStorage.setItem('username', username);
      localStorage.setItem('from_recovery', "true");
      setTimeout(()=> {navigate("/LogIn");}, 1000);
    }
    catch (error) {
      console.log('error recovering account', error.name);
      if(error.name==="CodeMismatchException"){
        no_verified_label.innerHTML=labels.code_invalid[lang];
      }
      if(error.name==="InvalidPasswordException"){
        no_verified_label.innerHTML=labels.password_8_characters[lang];
      }
      
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  async function handleSubmit(){
    let username="";
    let element=document.getElementById("form-email");
    let url='https://3c3fmx8bb7.execute-api.us-east-1.amazonaws.com/api/get_user'
      try{
          let response= await fetch(url+"/"+email, {method: 'get'});
          response= await response.json();
          username=response.length>0?response[0]:"";
          setUsername(username);
        }
        catch (error) {
        }
    if(username!==""){
      element.value=""
      setIsRecoveringAccount(true);
      recoverAccount(username);
      }
    else{
      document.getElementById("username_label").innerHTML=labels.email_not_registered[lang];
    }
  };

  const PasswordControl = () => {
    const [showPassword, setShowPassword] = useState(false);
  
    return (
        <div class="d-flex flex-row  mb-4">
            <img src={key} class="img-fluid me-3" ></img>
            <Form.Group className="mb-3" controlId="formPassword">
                <Form.Control type={showPassword ? "text" : "password"}/>
                <Form.Label>{labels.new_password[lang]}</Form.Label>
            </Form.Group>
            <Button onClick={() => setShowPassword(!showPassword)} variant="transparent" >
                <img src={showPassword ? eye_slash : eye} class="img-fluid" ></img>
            </Button> 
        </div>                                   
    );
  };

  const email_form=
  <section class="vh-100" style={{"background-color": "#00061a"}}>
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-6 col-xl-6">
          <div class="card text-black" style={{"border-radius": "25px"}}>
            <div class="card-body p-md-5">
                <div class="row">
                  <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">
                    <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">{labels.recover_account[lang]}</p>
                    <form class="mx-1 mx-md-4">
                      <div class="d-flex flex-row mb-4">
                        <div class="form-outline flex-fill mb-0">
                          <img src={envelope_at_fill} class="img-fluid me-3" ></img>
                          <input
                            id="form-email"
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={handleEmailChange}
                          />
                          <label class="form-label" for="form-email">{labels.enter_email_reestablish[lang]}</label>  
                        </div>
                      </div>
                      <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                        <label class="text-danger" id="username_label"></label>
                      </div>
                      <div class="d-flex flex-row mb-4">
                        <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">
                          <Button  onClick={handleSubmit}>{labels.send_verification_code[lang]}</Button>
                        </div>
                      </div>
                    </form>
                </div>                   
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  const recovery_form=
  <section class="vh-100" style={{"background-color": "#00061a"}}>
    <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-lg-6 col-xl-6">
          <div class="card text-black" style={{"border-radius": "25px"}}>
          <div class="card-body p-md-5">
              <div class="row">
                <div class="col-md-10 col-lg-12 col-xl-12 order-2 order-lg-1">
                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">{labels.recover_account[lang]}</p>
                <form class="mx-1 mx-md-4">
                  <div class="d-flex flex-row mb-4">
                  <div class="form-outline flex-fill mb-0">
                    <img src={envelope_check} class="img-fluid me-3" ></img>
                    <input
                      id="form-code"
                      onChange={(e) => {
                        validationCode= e.target.value;
                      }}
                      placeholder="00000"
                      type="text"
                      name="vc"
                    />
                    <label class="form-label" for="form-code">{labels.put_code[lang]}</label>
                  </div>
                </div>

                  <PasswordControl/>
                  <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                    <label class="text-danger" id="no_verified_label"></label>
                  </div>
                  <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4 ">
                    <label class="text-success" id="verified_label"></label>
                  </div>
                  <div class="d-flex mx-2 mb-3 mb-lg-4 ">
                    <label>{labels.password_8_characters[lang]}</label>
                  </div>
                  <div class="d-flex flex-row mb-4">
                    <Button onClick={() => verifyEmailValidationCode(username,validationCode)}>
                      {labels.verify_code[lang]}
                    </Button>
                  </div>
                </form>
                </div>                   
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  let display=null;
  if (isRecoveringAccount){
    display=recovery_form;
  }
  else{
    display=email_form;
  }

  return (
    display
  );
}

export default AccountRecovery;
